/**
 * @generated SignedSource<<9a905cf088402140275e4938acb92561>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type focusContentHero_content$data = {
  readonly content: {
    readonly customPromotionTitle?: string | null;
    readonly fallbackImage?: {
      readonly url: string;
    } | null;
    readonly gameTags?: ReadonlyArray<string>;
    readonly jackpot?: {
      readonly amount: number;
    } | null;
    readonly launchUrl?: string;
    readonly name?: string;
    readonly programExternalId?: string;
    readonly promotionId?: string;
    readonly promotionSlug?: string | null;
    readonly slug?: string;
  } | null;
  readonly ctaButtonText: string | null;
  readonly sellingText: {
    readonly jsonContent: {
      readonly json: string | null;
    };
  } | null;
  readonly " $fragmentType": "focusContentHero_content";
};
export type focusContentHero_content$key = {
  readonly " $data"?: focusContentHero_content$data;
  readonly " $fragmentSpreads": FragmentRefs<"focusContentHero_content">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "slug",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "focusContentHero_content",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "content",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "launchUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "gameTags",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Jackpot",
              "kind": "LinkedField",
              "name": "jackpot",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "amount",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Image",
              "kind": "LinkedField",
              "name": "fallbackImage",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "url",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "FocusContentHeroGame",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v0/*: any*/),
          "type": "FocusContentHeroPage",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "programExternalId",
              "storageKey": null
            }
          ],
          "type": "FocusContentHeroBonus",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "promotionId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "promotionSlug",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "customPromotionTitle",
              "storageKey": null
            }
          ],
          "type": "FocusContentHeroPromotion",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v0/*: any*/),
          "type": "FocusContentHeroTag",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ctaButtonText",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "HeroRichTextBlock",
      "kind": "LinkedField",
      "name": "sellingText",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Json",
          "kind": "LinkedField",
          "name": "jsonContent",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "json",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "FocusContentHeroFields",
  "abstractKey": "__isFocusContentHeroFields"
};
})();

(node as any).hash = "d6e567039a68fa2d5b69a6c1a547a66c";

export default node;
